<template>
  <div class="labelGenEdit">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'labelGen', params: {} }">
        Label Gen
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'labelGenEdit', params: { id: id } }">
        Edit Label Gen
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card>
      <v-card-title>Editor</v-card-title>
      <v-card-text>
        <v-sheet class="d-flex">
          <div>
            <label>Product</label>
            <a @click="activateProductLookup = true">{{product ? product.name:'Choose a product'}}</a>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>

    <product-lookup :activated="activateProductLookup" v-model="productId" @selected="assignProduct"/>
  </div>
</template>

<script>
import ProductLookup from '../product/ProductLookup.vue';

export default {
  name: "labelGen",
  data() {
    return {
      id: null,
      productId: null,
      product: null,
      activateProductLookup: false
    };
  },
  components: {
    ProductLookup
  },
  created() {
    this.id = this.$route.params.id;
    this.$emit("showParent", false);
  },
  methods: {
    assignProduct(product){
      console.log("selected product: " + product)
      this.product = product;
    }
  }
};
</script>
